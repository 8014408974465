import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Details from "../../components/modals/book/details";
import EditBook from "../../components/modals/book/EditBook";
import DeleteBook from "../../components/modals/book/DeleteBook";

const BookItem = ({ book, isAuthenticated, user }) => {
  const { _id, image, title, discription,bookAuthor, updatedAt } = book;

  return (
    <div className="col s12 " style={{ direction: "rtl" }}>
      <div className="card grid-2">
        <div className="card-image">
          <img src={image} alt="subjects" />
          <br />
          {isAuthenticated && user && user.rolls === 2 && (
            <Fragment>
              <EditBook correntBook={book} />
              <DeleteBook id={_id} />
            </Fragment>
          )}

          {/* <Details book={book} /> */}
        </div>
        <div className="card-stacked">
          <div className="card-content right-align">
            <h1>
              {" "}
              <u> {title}</u>
            </h1>
            <h3>{updatedAt}</h3>
            <p dangerouslySetInnerHTML={{ __html: discription }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

BookItem.propTypes = {
  book: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps)(BookItem);
