import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getNews } from "../../actions/news.action";
import AddNews from "../../components/modals/news/AddNews";
import NewsItems from "./NewsItems";
import Spinner from "../../components/Layout/Spinner";
import DBNavBar from "../../components/Layout/DBNavBar";
const NewsPage = ({
  getNews,
  news: { newss, loading },
  isAuthenticated,
  user,
}) => {
  useEffect(() => {
    getNews();
    // eslint-disable-next-line
  }, [loading]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid mt-6">
        <div className="row">
          <div className="col-md-10">
        <div className="card text-center  ">
          <h1> الاخبار</h1>
        </div>
        {isAuthenticated && user && user.rolls === 2 && (
          <div className="card">
            <AddNews />
          </div>
        )}
        {newss &&
          newss.map((n) => (
            <Fragment key={n._id}>
              <NewsItems news={n} />
            </Fragment>
          ))}
      </div>
      <div className="col-md-2">
            <DBNavBar />
          </div>
      </div>
          
        </div>
        
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  news: state.news,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getNews })(NewsPage);
