import React, { useEffect, useState } from "react";
import { Nav, Container, Navbar, NavDropdown } from "react-bootstrap";
import { logout } from "../../actions/auth";
import { Link } from "react-scroll";
import { connect } from "react-redux";
import * as route from "react-router-dom";
const NavBar = ({ user, logout, isAuthenticated }) => {
  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      id="header"
      className={scrollPosition > 50 && "sticky"}
      style={{ direction: "rtl" }}
    >
      <>
        <Navbar collapseOnSelect expand="lg" sticky="top">
          <Container>
            <Navbar.Brand>
              <route.Link to="/" className="navbar-brand">
                <img src="./img/mlogo.png" alt="logo" width="100" />
                <h3 className="d-inline align-middle"> </h3>
              </route.Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link>
                  <Link
                    to="showcase"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    الرئيسية{" "}
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    عن الجمعية
                  </Link>
                </Nav.Link>
                <NavDropdown title="البحث العلمي" id="basic-nav-dropdown" style={{zIndex : "3000" ,    right: '0 !important'}}>
                  <NavDropdown.Item
                    href="https://alqurtas.alandalus-libya.org.ly/ojs/index.php/qjhar"
                    target="_blank"
                  >
                    {" "}
                    مجلة القرطاس
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://alasala.alandalus-libya.org.ly/ojs/index.php/aj"
                    target="_blank"
                  >
                    {" "}
                    مجلة الاصالة
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="https://sclaehs.alandalus-libya.org.ly/wp/"
                    target="_blank"
                  >
                    {" "}
                    المؤتمر العلمي الثاني للجمعية
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <Nav.Link
                  href="https://alqurtas.alandalus-libya.org.ly/ojs/index.php/qjhar"
                  target="_blank"
                >
                  مجلة القرطاس
                </Nav.Link>
                <Nav.Link
                  href="https://alasala.alandalus-libya.org.ly/ojs/index.php/aj"
                  target="_blank"
                >
                  مجلة الاصالة
                </Nav.Link> */}
                <Nav.Link>
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    الصالون الثقافي
                  </Link>
                </Nav.Link>

                <Nav.Link>
                  {" "}
                  <Link
                    to="authors"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    منشوراتنا
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    اتصل بنا
                  </Link>
                </Nav.Link>
                {isAuthenticated ? (
                  <li class="nav-item">
                    <route.Link to="/dashboard/adv" class="nav-link">
                      لوحة التجكم
                    </route.Link>
                  </li>
                ) : (
                  <li class="nav-item">
                    <route.Link to="/login" class="nav-link">
                      تسجيل دخول
                    </route.Link>
                  </li>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    </header>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(NavBar);
