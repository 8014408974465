import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";

const Login = ({ login, isAuthenticated, user }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <section className="section section-login">
        <div className="container text-right">
          <Link to="/" className="btn bg-dark "><i class="fas fa-arrow-right"></i> الرجوع للرئيسية </Link>
          <div className="row">
            <div className="col s12 m8 offset-m2 l6 offset-l3 center-align">
              <div className="card-panel card bg-dark ">
                <h5>تسجيل الدخول للوحة التحكم / Login</h5>
                <br />
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="input-field ">
                    <label className="white-text" htmlFor="email">
                      البريد الالكتروني / Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      className="validate"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div className="input-field">
                    <label className="white-text" htmlFor="password">
                      كلمة المرور / Password
                    </label>
                    <input
                      type="password"
                      className="validate"
                      name="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                      minLength="6"
                      id="password"
                    />
                  </div>
                  <input
                    type="submit"
                    value="تسجيل الدخول / login"
                    className="btn btn-large btn-extended grey lighten-4 black-text"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
