import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addBook } from "../../../actions/book.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const AddBook = ({ addBook, setAlert }) => {
  const [addModals, setShow] = useState(false);
  const [Book, setBook] = useState({
    title: "",
    description: "",
    Url: "",
    bookAuthor:""
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, description, Url ,bookAuthor} = Book;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files);
  };
  const onChange = (e) => {
    setBook({ ...Book, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (title === "" || description === "" || file === "") {
      setAlert("ارجو تعبئة كل الحقول و تحميل الصور لهدا القسم", "danger");
    } else {
      addBook(file, title, description, Url,bookAuthor);
      setAlert(" تم إضافة القسم إلى اقسام المعهد", "success");
      setBook({
        title: "",
        description: "",
        Url: "",
      });
    }
    setShow(false);
  };

  return (
    <Fragment>
      <a
        href="#!"
        onClick={handleShow}
        className=" btn-outline btn-block btn-radius lead"
      >
        {" "}
        <FontAwesomeIcon icon={faPlus} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> إضافة كتاب </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <label htmlFor="file"> ادخل الكتاب وغلاف للكتاب </label>
            <br />
            <input id="file" type="file" onChange={onChangefile} multiple />
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان الكتاب"
              required
            />
             <FormInput
              name="bookAuthor"
              type="text"
              handleChange={onChange}
              value={bookAuthor}
              label="مؤلف الكتاب  "
              required
            />
            <FormInput
              name="Url"
              type="text"
              handleChange={onChange}
              value={Url}
              label="رمز الكتاب باللغة الانجليزية"
              required
            />

            <label htmlFor="firstName" className="active">
              مقدمة عن الكتاب
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setBook({ ...Book, description: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            إضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  addBook,
  setAlert,
})(AddBook);
