import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateNews } from "../../../actions/news.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const UpdateNews = ({ updateNews, correntNews, setAlert }) => {
  const [addModals, setShow] = useState(false);

  const [news, setNews] = useState({
    id: correntNews._id,
    title: correntNews.title,
    discription: correntNews.discription,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, discription } = news;

  const onChange = (e) => {
    setNews({ ...news, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateNews(news);
    setAlert("تم التعديل على الخبر بنجاح", "success");
    setShow(false);
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line */}
      <a onClick={handleShow}>
        {" "}
        <FontAwesomeIcon icon={faEdit} />
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> التعديل على الخبر </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان الخبر"
              required
            />

            <label htmlFor="firstName" className="active">
              الخبر
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={discription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setNews({ ...news, discription: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            تعديل{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  updateNews,
  setAlert,
})(UpdateNews);
