import React, { Fragment } from "react";

import { connect } from "react-redux";
import DeleteAdv from "../../components/modals/advs/DeleteAdvs";
import EditAdvs from "../../components/modals/advs/EditAdvs";
import Details from "../../components/modals/advs/details";

const AdvsItem = ({ adv, isAuthenticated, user }) => {
  const { _id, image, title, discription, updatedAt } = adv;

  return (
    <div className="col s12 " style={{ direction: "rtl" }}>
      <div className="card grid-2">
        <div className="card-image">
          <img src={image} alt={title} />
          <br />
          {isAuthenticated && user && user.rolls === 2 && (
            <Fragment>
              <EditAdvs correntAdv={adv} />
              <DeleteAdv id={_id} />
            </Fragment>
          )}

        </div>
        <div className="card-stacked">
          <div className="card-content right-align">
            <h5>
              {title}
            </h5>
            <small>{updatedAt}</small>
            <p className="text-over" dangerouslySetInnerHTML={{ __html: discription }}></p>
            <Details adv={adv} />

          </div>
        </div>
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AdvsItem);
