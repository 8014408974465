import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addAdv } from "../../../actions/advs.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const AddAdvs = ({ addAdv, setAlert }) => {
  const [addModals, setShow] = useState(false);
  const [adv, setAdv] = useState({
    title: "",
    description: "",
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, description } = adv;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files);
  };
  const onChange = (e) => {
    setAdv({ ...adv, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (title === "" || description === "" || file === "") {
      setAlert("ارجو تعبئة كل الحقول و تحميل الصور لهدا الإعلان", "danger");
    } else {
      addAdv(file, title, description);
      setAdv({
        title: "",
        description: "",
      });
      setAlert("تمت إضافة الإعلان بنجاح", "success");
      setShow(false);
    }
  };

  return (
    <Fragment>
      <a
        href="#!"
        onClick={handleShow}
        className=" btn-outline btn-block btn-radius lead"
      >
        {" "}
        <FontAwesomeIcon icon={faPlus} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> إضافة إعلان </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <label htmlFor="file"> ادخل صور الإعلان </label>
            <br />
            <input id="file" type="file" onChange={onChangefile} multiple />
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان الإعلان"
              required
            />

            <label htmlFor="firstName" className="active">
              الاعلان
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAdv({ ...adv, description: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            إضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  addAdv,
  setAlert,
})(AddAdvs);
