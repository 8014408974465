import React from 'react'
import { Link } from 'react-router-dom'
import Details from "../../components/modals/book/details"

const BookItem = ({book}) => {
    const {image, title, discription, bookContent, bookAuthor, author :{name} } = book
    return (
        <>
        
                            <div class="card" style={{width :"18rem", maxHeight : "650px"}}>
                                {/* صورة الكتاب */}
                                <img src={image} class="card-img-top" alt={title} />
                                        <div class="card-body">
                                            <Link to="/">
                                                <h5 class="card-title">
                                                  {title}
                                                </h5>
                                            </Link>
                                            <small> المؤلف :  {bookAuthor} </small>
                                            <p class="card-text text-overf text-justify" dangerouslySetInnerHTML={{__html : discription }}>
                                            </p>
                                            
                                        </div>
                                        
                            </div>
                            <Details  book ={book}/>
                            </>
                        
    )
}

export default BookItem
