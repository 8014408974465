import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { deleteBook } from "../../../actions/book.action";
import { setAlert } from "../../../actions/alert";

const DeleteBook = ({ deleteBook, id, setAlert }) => {
  const [addModals, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const DeleteC = () => {
    deleteBook(id);
    setAlert(" تم حذف  القسم ", "success");
    setShow(false);
  };
  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a onClick={handleShow}>
        {" "}
        <FontAwesomeIcon icon={faTrashAlt} />{" "}
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> تأكيد الطلب </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ؟ هل انت متآكد انك تريد حئف هدا القسم
          <Modal.Footer>
            <button
              className="btn-custom btn-dark center"
              onClick={handleClose}
            >
              لا
            </button>
            <button className="btn-custom btn-danger center" onClick={DeleteC}>
              نعم
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  deleteBook,
  setAlert,
})(DeleteBook);
