import React from "react";
import Spinner from "../components/Layout/Spinner";

import "react-responsive-carousel/lib/styles/carousel.css";
import { Carousel } from "react-responsive-carousel";

const Rses = ({ pictures }) => {
  return (
    <section id="gallery"  style={{ direction: "ltr" }}>
      <div>
        {pictures && pictures !== "" ? (
          <Carousel>
            {pictures.map((pic, index) => (
              <div>
                <img src={`${pic}`} alt="News Images"/>
              </div>
            ))}
          </Carousel>
        ) : (
          <Spinner />
        )}
      </div>
    </section>
  );
};

export default Rses;
