import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import PrivateRoute from "./components/routing/PrivateRoute";
import SuperPrivateRoute from "./components/routing/SuperPrivateRoute";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Register";
import AdvsPage from "./pages/ADVS/advs.page";
import BooksPage from "./pages/Book/Book.page";
import HomePage from "./pages/HomePage";
import Alert from "./components/Layout/Alert";
import Progress from "./components/Layout/Progress";

import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import newsPage from "./pages/News/news.page";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <div className="container">
          <Alert />
          <Progress />
        </div>

        <Switch>
          <Route path="/" exact component={HomePage} />
          <PrivateRoute path="/dashboard/adv" exact component={AdvsPage} />
          <PrivateRoute path="/dashboard/news" exact component={newsPage} />
          <PrivateRoute path="/dashboard/book" exact component={BooksPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
        </Switch>
      </Router>
    </Provider>
  );
};
export default App;
