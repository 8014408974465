import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getBooks } from "../../actions/book.action";
import AddBooks from "../../components/modals/book/AddBook";
import BookItems from "./BookItems";
import Spinner from "../../components/Layout/Spinner";
import DBNavBar from "../../components/Layout/DBNavBar";
const BooksPage = ({
  getBooks,
  books: { books, loading },
  isAuthenticated,
  user,
}) => {
  useEffect(() => {
    getBooks();
    // eslint-disable-next-line
  }, []);
  return loading ? (
    <Spinner />
  ) : (
    <div className="container-fluid mt-6">
      <div className="row">
        <div className="col-md-10"><Fragment>
        <div className="card text-center">
          <h1> الكتب</h1>
          {isAuthenticated && user && user.rolls === 2 && (
            <Fragment>
              <AddBooks />
            </Fragment>
          )}
        </div>

        {books.map((book) => (
          <Fragment key={book._id}>
            <BookItems book={book} />
          </Fragment>
        ))}
      </Fragment>
      </div>
        <div className="col-md-2">
          <DBNavBar />
        </div>
      </div>
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  books: state.books,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getBooks })(BooksPage);
