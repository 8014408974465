import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getAdvs } from "../../actions/advs.action";
import AddAdvs from "../../components/modals/advs/AddAdvs";
import AdvsItems from "./AdvsItems";
import Spinner from "../../components/Layout/Spinner";
import DBNavBar from "../../components/Layout/DBNavBar";
const AdvsPage = ({
  getAdvs,
  advs: { advs, loading },
  isAuthenticated,
  user,
}) => {
  useEffect(() => {
    getAdvs();
    // eslint-disable-next-line
  }, [loading]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid mt-6">
        <div className="row">
          
          <div className="col-md-10">
          <div className="card text-center">
          <h1> الاعلانات</h1>
        </div>
        {isAuthenticated && user && user.rolls === 2 && (
          <div className="card">
            <AddAdvs />
          </div>
        )}

        {advs.map((adv) => (
          <Fragment key={adv._id}>
            <AdvsItems adv={adv} />
          </Fragment>
        ))}
      </div>
      <div className="col-md-2 ">
            <DBNavBar />
          </div>
          </div>
         
        </div>
        
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  advs: state.advs,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getAdvs })(AdvsPage);
