import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";

const NavBar = ({ user, logout, isAuthenticated }) => {
  return (
    <Fragment>
<ul className="nav flex-column card">
<li className="nav-item">
    <a className="nav-link disabled"  tabindex="-1" aria-disabled="true">لوحة التحكم</a>
  </li>
  <hr />
  <li className="nav-item">
    <Link className="nav-link active" aria-current="page" to="/">الرئيسية</Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link active" aria-current="page" to="/dashboard/adv">الإعلانات</Link>
  </li>
  <hr />
  <li className="nav-item">
    <Link className="nav-link" to="/dashboard/news">الأخبار</Link>
  </li>
  <hr />
  <li className="nav-item">
    <Link className="nav-link" to="/dashboard/book">الكتب</Link>
  </li>
  <li className="nav-item">
  {/* eslint-disable-next-line */}
    <a className="nav-link" onClick={()=>logout}><FontAwesomeIcon icon={faRunning}/> تسجيل خروج</a>
  </li>
  
</ul>
      </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(NavBar);
