import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAdvs } from "../actions/advs.action";
import { getNews } from "../actions/news.action";
import { getBooks } from "../actions/book.action";
import Spinner from "../components/Layout/Spinner";
import AdvsItemMedia from "./ADVS/AdvsItems";
import NewsItemMedia from "./News/NewsItems";
import Slider from "../utils/slider";
import NavBar from "../components/Layout/NavBar";

const HomePage = ({
  getAdvs,
  getNews,
  getBooks,
  news: { newss },
  advs: { advs, loading },
  books: { books },
}) => {
  useEffect(() => {
    getBooks();
    getNews();
    getAdvs();
  }, [loading]);
  return loading ? (
    <Spinner />
  ) : (
    <>
      <NavBar />
      <section id="showcase" class="py-5">
        <div class="primary-overlay text-white">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 text-center">
                <h1 class="display-4 mt-5 pt-5">
                  نسعى للاهتمام بالمثقفين والمبدعين في شتى المجالات ...
                </h1>
                <p class="lead">
                  تسعى الجمعية الليبية للعلوم التربوية و الانسانية للاهتمام
                  بقضايا التربية والتعليم من خلال ما تقوم به من أنشطة علمية
                  تربوية مختلفة تهدف إلى معالجة قضايا التعليم ، والرقي بها إلى
                  الأفضل
                </p>
                <a href="#about" class="btn btn-dark">
                  المزيد <i class="fas fa-arrow-left"></i>
                </a>
              </div>
              <div class="col-lg-6">
                <img
                  src="img/book.png"
                  alt=""
                  class="img-fluid d-none d-lg-block"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="newss">
        <div className="row container-fluid content ">
          <div className="news col-md-6 content ">
            <div className="row valign-wrapper px-2">
              <p className="col-md-6 lead right-align">الاخبار</p>
            </div>
            <div className="advs-news">
              {newss.map((news) => (
                <div className="col s12" key={news._id}>
                  <NewsItemMedia news={news} />
                </div>
              ))}
            </div>
          </div>

          <div className="news col-md-6 ">
            <div className="row valign-wrapper px-3">
              <p className="col-md-6 lead right-align">الاعلانات</p>
            </div>
            <div className="advs-news">
              {advs &&
                advs.map((adv) => (
                  <div className="col s12" key={adv._id}>
                    <AdvsItemMedia adv={adv} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="py-5 text-center bg-light">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="info-header mb-5">
                <h1 class="text-primary pb-3">عن الجمعية</h1>
                <p class="lead pb-3">
                  تسعى الجمعية الليبية للعلوم التربوية و الانسانية للاهتمام
                  بقضايا التربية والتعليم من خلال ما تقوم به من أنشطة علمية
                  تربوية مختلفة تهدف إلى معالجة قضايا التعليم ، والرقي بها إلى
                  الأفضل
                </p>
              </div>
              <div id="accordion">
                <div class="card">
                  <div class="card-header">
                    <h5 class="mb-0">
                      <div
                        href="#collapse1"
                        data-toggle="collapse"
                        data-parent="#accordion"
                      >
                        <h3>
                          <i class="fas fa-arrow-circle-down"></i> أهدافنا
                        </h3>
                      </div>
                    </h5>
                  </div>
                  <div id="collapse1" class="collapse show">
                    <div class="card-body">
                      <h4 class="text-muted text-right gouls">
                        تسعى الجمعية إلى تحقيق الأهداف التالية : <br /> • إقامة
                        الدورات العلمية والمنهجية لطلاب المدراس العامة والجامعات
                        عن طريق الجمعية مع أمكانية المساهمة مع الجهات الأخرى.
                        <br />
                        • إقامة ورش العمل والندوات والمؤتمرات العلمية التي تعالج
                        قضايا التعليم والتربية، وإقامة المحاضــــرات التوعوية
                        والتعليمية في مختلف مؤسسات المجتمع.
                        <br />
                        • التعاون مع الجمعيات العلمية ذات الطبية المشابهة،
                        وإقامة مشاريع مشتركة تهدف لخدمة الصالح العام.
                        <br />
                        • تشكيل مجموعات بحثية والإشــــراف عليها وتوجيهها نحو
                        تنفيذ أبحاث موثوقة تخدم مجال التعليم والتربية بما يعود
                        بالنفع على المجتمع عموما.
                        <br />
                        • إصدار مجلة علمية محكمة تُعنى بنشر مختلف البحوث
                        والدراسات العلمية الجادة والرصينة.
                        <br />
                        • تشجيع المؤلفين وأصحاب العقول البحثية والتعاون معهم من
                        خلال التعريف بإنتاجهم العلمي وإسهاماتهم الأدبية والترويج
                        لها بين المهتمين.
                        <br />• تقديم الاستشارات العلمية لمختلف مؤسسات المجتمع
                        عن طريق اختصاصيين ممن يملكون المعرفة ويتمتعون بالخبرة
                        والدراية. <br />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="authors" class="my-5 text-center">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="info-header mb-5">
                <h1 class="text-primary pb-3">منشوراتنا</h1>
                <p class="lead">
                  يمكنك الاطلاع علي الابحات المنشورة حسب كل عدد
                </p>
              </div>
            </div>
          </div>
          <div class=" mt-3" style={{ direction: "ltr" }}>
            {books && <Slider books={books} />}
          </div>
        </div>
      </section>
      <section id="contact" class="bg-light py-5 ">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 text-right">
              <h3>اتصل بنا</h3>
              <p class="lead pull-right">
                لمزيدا من المعلومات والاستفسارات الرجاء عدم التردد في مراسلتنا!
              </p>
              <form>
                <div class="input-group input-group-lg mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-user"></i>
                    </span>
                  </div>
                  <input type="text" class="form-control" placeholder="الاسم" />
                </div>
                <div class="input-group input-group-lg mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="البريد الالكتروني"
                  />
                </div>
                <div class="input-group input-group-lg mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-pencil-alt"></i>
                    </span>
                  </div>
                  <textarea
                    class="form-control"
                    placeholder="محتوى الرسالة"
                    rows="5"
                  ></textarea>
                </div>
                <input
                  type="submit"
                  value="ارسال"
                  class="btn btn-primary btn-block btn-lg"
                />
              </form>
            </div>
            <div class="col-lg-3 align-self-center">
              <img src="img/mlogo.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section id="main-footer" class="py-2 bg-info text-white">
        <div class="container">
          <div class="row text-center">
            <div class="col-md-6 m-auto">
              <p class="lead">
                &copy;الجمعية الليبية للعلوم التربوية و الانسانية
                {new Date().getFullYear()} <span id="year"></span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  news: state.news,
  advs: state.advs,
  books: state.books,
});
export default connect(mapStateToProps, { getNews, getAdvs, getBooks })(
  HomePage
);
