export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const REMOVE_PROGRESS = "REMOVE_PROGRESS";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";

export const GET_ADVS = "GET_ADVS";
export const GET_ADV = "GET_ADV";
export const ADD_ADV = "ADD_ADV";
export const UPDATE_ADV = "UPDATE_ADV";
export const DELETE_ADV = "DELETE_ADV";
export const ERROR_ADV = "ERROR_ADV";

export const GET_NEWSS = "GET_NEWSS";
export const GET_NEWS = "GET_NEWS";
export const ADD_NEWS = "ADD_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const ERROR_NEWS = "ERROR_NEWS";

export const GET_BOOKS = "GET_BOOKS";
export const GET_BOOK = "GET_BOOK";
export const ADD_BOOK = "ADD_BOOK";
export const UPDATE_BOOK = "UPDATE_BOOK";
export const DELETE_BOOK = "DELETE_BOOK";
export const ERROR_BOOK = "ERROR_BOOK";

export const GET_OFFICES = "GET_OFFICES";
export const GET_OFFICE = "GET_OFFICE";
export const ADD_OFFICE = "ADD_OFFICE";
export const UPDATE_OFFICE = "UPDATE_OFFICE";
export const DELETE_OFFICE = "DELETE_OFFICE";
export const ERROR_OFFICE = "ERROR_OFFICE";

export const ADD_STUDENT = "ADD_STUDENT";
export const ERROR_STUDENT = "ERROR_STUDENT";
export const GET_STUDENTS = "GET_STUDENTS";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";

export const GET_MEMBERS = "GET_MEMBERS";
export const ADD_MEMBER = "ADD_Member";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const ERROR_MEMBER = "ERROR_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const GET_MEMBER = "GET_MEMBER";

export const MEMBER_REGISTER_SUCCESS = "MEMBER_REGISTER_SUCCESS";
export const MEMBER_REGISTER_FAIL = "MEMBER_REGISTER_FAIL";

export const GET_SUBJECTS = "GET_SUBJECTS";
export const ERROR_SUBJECT = "ERROR_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const GET_EXAMS = "GET_EXAMS";
export const GET_FILES = "GET_FILES";
export const GET_SUBJECT = "GET_SUBJECT";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const CLEAR_SUBJECT = "CLEAR_SUBJECT";

export const FILTER_STUDENTS = "FILTER_STUDENTS";
