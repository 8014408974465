import axios from "axios";
import {
  GET_ADVS,
  ADD_ADV,
  UPDATE_ADV,
  DELETE_ADV,
  ERROR_ADV,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
} from "./types";

// GET CONFERANCES
export const getAdvs = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/advs");
    dispatch({
      type: GET_ADVS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ADV,
      payload: { msg: error.response, status: error.response },
    });
  }
};

export const addAdv = (file, title, description) => async (dispatch) => {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append("advImage", file[i]);
  }
  formData.append("title", title);
  formData.append("discription", description);
  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 5000);
        }
      },
    };
    const res = await axios.post("/api/advs", formData, options);
    dispatch({
      type: ADD_ADV,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_ADV,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateAdv = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(`/api/advs/${formData.id}`, formData, config);
    dispatch({
      type: UPDATE_ADV,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_ADV,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteAdv = (id) => async (dispatch) => {
  try {
    //eslint-disable-next-line
    const res = await axios.delete(`/api/advs/${id}`);
    dispatch({
      type: DELETE_ADV,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_ADV,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
