import React from 'react'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import BookItem from "../pages/Book/BookItem"

const Slider = ({books}) => {
    return (
        <OwlCarousel
        className="owl-thene"
        items="4"
        margin={7}
        autoPlay
        nav={true}
        autoWidth
        dots={false}
        loop
        >
        {books && books.map(book=> <div className="item" key={book._id}>
                      <BookItem book={book}  />  
                      </div>

                      )}
         
          
        </OwlCarousel>
    )
}

export default Slider
