import axios from "axios";
import {
  GET_BOOKS,
  ADD_BOOK,
  UPDATE_BOOK,
  DELETE_BOOK,
  ERROR_BOOK,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
} from "./types";

// GET CONFERANCES
export const getBooks = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/book");
    dispatch({
      type: GET_BOOKS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_BOOK,
      payload: { msg: error.response, status: error.response.status },
    });
  }
};

export const addBook = (file, title, description, Url,bookAuthor) => async (
  dispatch
) => {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append("bookImage", file[i]);
  }
  formData.append("title", title);
  formData.append("discription", description);
  formData.append("Url", Url);
  formData.append("bookAuthor", bookAuthor);
  try {
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}% `);
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: { loaded, total, percent },
        });
        if (percent === 100) {
          setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 5000);
        }
      },
    };
    const res = await axios.post("/api/book", formData, options);
    dispatch({
      type: ADD_BOOK,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_BOOK,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const updateBook = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/book/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_BOOK,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_BOOK,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteBook = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/book/${id}`);
    dispatch({
      type: DELETE_BOOK,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_BOOK,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
