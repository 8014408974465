import {
  GET_BOOKS,
  GET_BOOK,
  ADD_BOOK,
  UPDATE_BOOK,
  ERROR_BOOK,
  DELETE_BOOK,
} from "../actions/types";

const inialState = {
  books: [],
  book: null,
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BOOKS:
      return {
        ...state,
        books: payload,
        loading: false,
      };
    case GET_BOOK:
      return {
        ...state,
        book: payload,
        loading: false,
      };
    case ADD_BOOK:
      return {
        ...state,
        books: [...state.books, payload],
        loading: false,
      };
    case UPDATE_BOOK:
      return {
        ...state,
        books: state.books.map((book) =>
          book._id === payload._id ? payload : book
        ),
      };
    case DELETE_BOOK:
      return {
        ...state,
        books: state.books.filter(
          (book) => book._id !== payload
        ),
      };
    case ERROR_BOOK:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
