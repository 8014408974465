import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateAdv } from "../../../actions/advs.action";
import { setAlert } from "../../../actions/alert";
import "../bootstrap.css";

const UpdateAdvs = ({ updateAdv, correntAdv, setAlert }) => {
  const [addModals, setShow] = useState(false);

  const [adv, setAdv] = useState({
    id: correntAdv._id,
    title: correntAdv.title,
    discription: correntAdv.discription,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, discription } = adv;

  const onChange = (e) => {
    setAdv({ ...adv, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateAdv(adv);
    setAlert("تم التعديل علي بيانات الإعلان", "success");
    setShow(false);
  };

  return (
    <Fragment>
       {/* eslint-disable-next-line */}
      <a onClick={handleShow}>
        {" "}
        <FontAwesomeIcon icon={faEdit} />
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> التعديل على الإعلان </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان الإعلان"
              required
            />

            <label htmlFor="firstName" className="active">
              الاعلان
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={discription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAdv({ ...adv, discription: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            إضافة{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  updateAdv,
  setAlert,
})(UpdateAdvs);
