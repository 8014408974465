import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateBook } from "../../../actions/book.action";
import "../bootstrap.css";
import { setAlert } from "../../../actions/alert";

const UpdateBook = ({
  updateBook,
  correntBook,
  setAlert,
}) => {
  const [addModals, setShow] = useState(false);

  const [Book, setBook] = useState({
    id: correntBook._id,
    title: correntBook.title,
    discription: correntBook.discription,
    Url: correntBook.Url,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { title, discription, Url } = Book;

  const onChange = (e) => {
    setBook({ ...Book, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateBook(Book);
    setAlert("تم التعديل على بيانات القسم ", "success");
    setShow(false);
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line */}
      <a onClick={handleShow}>
        {" "}
        <FontAwesomeIcon icon={faEdit} />
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> التعديل على القسم </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان القسم"
              required
            />
            <FormInput
              name="Url"
              type="text"
              handleChange={onChange}
              value={Url}
              label="رمز القسم باللغة الانجليزية"
              required
            />

            <label htmlFor="firstName" className="active">
              عن القسم
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={discription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setBook({ ...Book, discription: data });
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark btn-block"
            type="submit"
            onClick={onSubmit}
          >
            {" "}
            تعديل{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  updateBook,
  setAlert,
})(UpdateBook);
