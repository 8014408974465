import { combineReducers } from "redux";
import auth from "./auth";
import advs from "./advs.reduser";
import news from "./news.reduser";
import books from "./books.reduser";
import alert from "./alert";
import progress from "./Progress";
export default combineReducers({
  auth,
  advs,
  news,
  alert,
  books,
  progress,
});
