import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";

const Details = ({ book: { _id, title, discription, bookAuthor,bookContent } }) => {
  const [addModals, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a className="btn btn-dark text-white btn-block" onClick={handleShow}>
        {" "}
        التفاصيل
      </a>
      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h5 className="center-align">{title}</h5>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{ lineHeight: "2.4rem", fontSize: "1.3rem" }}>
            <ul className="right-align">
            <small className="text-rigth">الكتاب للمؤلف :  {bookAuthor}</small>

              <li
                dangerouslySetInnerHTML={{ __html: discription }}
                className="text-justify p-2"
              ></li>
             
            </ul>
           
          </div>
          <Modal.Footer>
          <a className="btn btn-secondry" href={bookContent}> فتح الكتاب</a>
            <button
              className="btn-custom btn-dark center"
              onClick={handleClose}
            >
              اغلاق
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Details;
